import TooltipWrapper from "../../components/CreateOilRequestPage/sub/TooltipWrapper";
import { OilRequestOilType, OilRequestOilUsageType } from "../../utils/config";
import { getNumberDisplayValue } from "../../utils/format";

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOil(oilType) {
    switch (oilType) {
        case OilRequestOilType.Petrol95:
            return "95";
        case OilRequestOilType.Petrol98:
            return "98";
        case OilRequestOilType.Diesel:
            return "D";
        case OilRequestOilType.DieselSpecial:
            return "EDK";
        case OilRequestOilType.HydrotreatedVegetableOil:
            return "HVO";
        default:
            return "";
    }
}

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOilUsage(oilType) {
    switch (oilType) {
        case OilRequestOilUsageType.Wholesale:
            return "Hulgimüük";
        case OilRequestOilUsageType.Agriculture:
            return "Põllumajandus";
        case OilRequestOilUsageType.Fishing:
            return "Kalapüük";
        default:
            return "";
    }
}

const Oil = ({ oil }) => {

    if (!oil.marking) {
        return (
            <div className="oilItem">
                <div className="oilItem__ring">
                    <span>{getNameForOil(oil.oilType)}</span>
                </div>
                <div>
                    <div className="oilItem__amount">{getNumberDisplayValue(oil.amount)} L</div>
                    <div className="oilItem__description">{getNameForOilUsage(oil.oilUsageType)}</div>
                </div>
            </div>
        );
    }

    return (
        <TooltipWrapper
            id={oil.oilType} text={oil.marking}>
            <div className="oilItem">
                <div className="oilItem__ring">
                    <span>{getNameForOil(oil.oilType)}</span>
                </div>
                <div>
                    <div className="oilItem__amount">{getNumberDisplayValue(oil.amount)} L</div>
                    <div className="oilItem__description">{getNameForOilUsage(oil.oilUsageType)}</div>
                </div>
            </div>
        </TooltipWrapper>
    );
};

export default Oil;
