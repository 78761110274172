import React from "react";
import { selectUserDisplayName } from "../../../state/user-companies";
import { connect } from "react-redux";
import Section from "../../Section";
import { Link } from "react-router-dom";
import { getRoleDisplayName } from "../../../utils/user";
import Icons from "./Icons";

const getInitials = (name: string) => {
    if (typeof name !== 'string') {
        return 'N/A';
    }
    const words = name.split(' ');
    const initials = words.map(word => word.charAt(0)).join('');
    return initials.slice(0, 2).toUpperCase();
};

type Props = {
    userDisplayName?: string;
    user: any;
};

class ProtectedHeader extends React.PureComponent<Props> {
    render() {
        const { userDisplayName, user } =
            this.props;
        return (
            <header style={{ height: '89px' }}>
                <Section backgroundColor="#1A273D">
                    <div className="header">
                        <Link to="/oil-requests">
                            {Icons.TurgoilLogo}
                        </Link>
                        <div className="userDetails">
                            <div className="userIcon">
                                <div className="userIconText">
                                    {getInitials(userDisplayName)}
                                </div>
                            </div>
                            <div className="userText">
                                <div className="userRole">
                                    {getRoleDisplayName(user.role)}
                                </div>
                                <div className="userName">
                                    {userDisplayName}
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        userDisplayName: selectUserDisplayName(state),
        user: state.userReducer.user,
    };
}

export default connect(mapStateToProps)(ProtectedHeader);