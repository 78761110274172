export default function Counters({oil_request_count, total_sum, liters}) {
        return (
            <div className="counters">
                <div className="counters__content">
                    <div className="counters__counter">
                        <div className="counters__title">Üldsumma</div>
                        <div className="counters__value">{total_sum} €</div>
                    </div>
                    <div className="counters__divider">
                        <hr />
                    </div>
                    <div className="counters__counter">
                        <div className="counters__title">Vahendatud liitrid</div>
                        <div className="counters__value">{liters} l</div>
                    </div>
                    <div className="counters__divider">
                        <hr />
                    </div>
                    <div className="counters__counter">
                        <div className="counters__title">Hangete arv</div>
                        <div className="counters__value">{oil_request_count}</div>
                    </div>
                </div>
            </div>
        );
    
}