import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { destroyAuthentication, getRoleDisplayName } from "../../../utils/user";
import { Routes } from "../../../utils/routes";
import { selectUserDisplayName } from "../../../state/user-companies";
import { UserRole } from "../../../utils/config";
import Icons from "./Icons";

function isConversations(path) {
  const reConversations = /\/conversations/g;
  return reConversations.test(path);
}

const getInitials = (name: string) => {
  if (typeof name !== 'string') {
    return 'N/A';
  }
  const words = name.split(' ');
  const initials = words.map(word => word.charAt(0)).join('');
  return initials.slice(0, 2).toUpperCase();
};

const showCount = (number: number): string => {
  if (number > 9) {
    return "9+"
  } else {
    return number.toString();
  }
};

type Props = {
  pathname: string;
  user: any;
  userCompanies: any[];
  userConversations: any[];
  userNotifications: any[];
  userDisplayName?: string;
};

class ProtectedSidebarDesktop extends React.PureComponent<Props> {
  handleLogout = (e) => {
    e.preventDefault();
    destroyAuthentication();
    window.location.href = Routes.SignIn;
  };

  getUnreadConversations = () => {
    const { user, userCompanies, userConversations, userNotifications } =
      this.props;

    let count = 0;
    userConversations.forEach((conversation) => {
      const lastMessage = conversation.messages[0];
      if (!lastMessage) {
        return;
      }

      if (lastMessage.from.userId === user.id) {
        return;
      }
      if (
        lastMessage.from.companyId &&
        userCompanies.some(
          (company) => company.id === lastMessage.from.companyId,
        )
      ) {
        return;
      }

      if (!lastMessage.readDatetime) {
        count += 1;
      }
    });

    const lastNotification = userNotifications.slice(-1).pop();
    if (lastNotification && !lastNotification.readDatetime) {
      count += 1;
    }

    return count;
  };

  render() {
    const { pathname, user, userDisplayName } = this.props;
    const unreadConversations = this.getUnreadConversations();

    return (
      <>
        <div className="nav-container">
          <div className="top-container">
            <div className="user-details">
              <div className="user-details__icon">
                <div className="user-details__icon-text">
                  {getInitials(userDisplayName)}
                </div>
              </div>
              <div className="user-details__description">
                <div className="user-details__role">
                  {getRoleDisplayName(user.role)}
                </div>
                <div className="user-details__name">
                  {userDisplayName}
                </div>
              </div>
            </div>
            <div className="links">
              <div>
                <Link
                  to="/oil-requests/create"
                  className={`protected-link ${pathname === "/oil-requests/create" ? "protected-link-active" : ""}`}
                >
                  {pathname === "/oil-requests/create" ?
                    <div>{Icons.PlusYellow}</div>
                    :
                    <div>{Icons.Plus}</div>
                  }
                  Uus hange
                </Link>
              </div>
              <div>
                <Link
                  to="/oil-requests"
                  className={`protected-link ${pathname === "/oil-requests" ? "protected-link-active" : ""}`}
                >
                  {pathname === "/oil-requests" ?
                    <div>{Icons.CopyYellow}</div>
                    :
                    <div>{Icons.Copy}</div>
                  }
                  Hanked
                </Link>
              </div>
              <div>
                <Link
                  to="/conversations"
                  className={`protected-link ${isConversations(pathname) ? "protected-link-active" : ""}`}
                >
                  {isConversations(pathname) ?
                    <div>{Icons.BellYellow}</div>
                    :
                    <div>{Icons.Bell}</div>
                  }
                  Sõnumid
                  {unreadConversations > 0 && (
                    <span className="badge custom-badge"> {showCount(unreadConversations)}</span>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  to="/settings"
                  className={`protected-link ${pathname === "/settings" ? "protected-link-active" : ""}`}
                >
                  {pathname === "/settings" ?
                    <div>{Icons.SettingsYellow}</div>
                    :
                    <div>{Icons.Settings}</div>
                  }
                  Seaded
                </Link>
              </div>
              {user.role === "Admin" && (
                <div>
                  <Link
                    to="/admin"
                    className={`protected-link ${pathname === "/admin" ? "protected-link-active" : ""}`}

                  >
                    {pathname === "/admin" ?
                      <div>{Icons.SettingsYellow}</div>
                      :
                      <div>{Icons.Settings}</div>
                    }
                    Administratsioon
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div>
            <Link to="#" onClick={this.handleLogout} className="logout">
              <div>{Icons.Logout}</div>
              <div>
                Logi välja
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userConversations: state.userConversationsReducer.conversations,
    user: state.userReducer.user,
    userCompanies: state.userCompaniesReducer.companies,
    userNotifications: state.userNotificationsReducer.notifications,
    userDisplayName: selectUserDisplayName(state),
  };
}

export default connect(mapStateToProps)(ProtectedSidebarDesktop);
