import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { getNameForOil } from "../../../common/Oils";
import Select from "../../../common/Select";
import { OilRequestOilType } from "../../../utils/config";
import { getNumberDisplayValueVatIncluded } from "../../../utils/format";
import { oilMarkingOptions } from "../../CreateOilRequestPage/helpers";

export type Bid = {
  oilId: number;
  price: string;
  marking?: string;
  cloudPoint?: string;
  coldFilterPlugPoint?: string;
};

type Props = {
  index: number;
  oil: {
    oilType: string;
  };
  bid: Bid;
  onChange: (bid: Bid, index: number) => void;
  errors?: Partial<Record<keyof Omit<Bid, "oilId">, string>>;
};

const BidForm: React.FC<Props> = ({
  oil,
  bid,
  errors,
  onChange,
  index,
}): React.ReactElement => {
  const [dirtyBid, setDirtyBid] = useState<Bid>({
    ...bid,
  });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const nextValue = {
      ...dirtyBid,
      [name]: value,
    }
    setDirtyBid(nextValue);
    onChange(nextValue, index);
  };

  const handleSelectChange = (
    o: { value: any } | null,
    m: { name?: string },
  ) => {
    const { value } = o || {};
    const { name } = m;
    const nextValue = {
      ...dirtyBid,
      [name]: value,
    }
    setDirtyBid(nextValue);
    onChange(nextValue, index);
  };

  const isCloudPoint = (): boolean => {
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  const isColdFilterPlugPoint = (): boolean => {
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["E", "F", "A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  return (
    <>
      <div className="createOrEditBid__formFields__oilSection">
        <div className="createOrEditBid__formFields__oilSection__title">
          {getNameForOil(oil.oilType)}
        </div>
        <div className="createOrEditBid__formFields__oilSection__inputContainers">
          <div className="createOrEditBid__formFields__oilSection__inputContainer">
            <Form.Label className="createOrEditBid__formFields__oilSection__label">
              Pakkumine
            </Form.Label>
            <InputGroup className="createOrEditBid__formFields__oilSection__input">
              <Form.Control
                style={{ fontSize: '14px', background: '#F7F7F7' }}
                placeholder="Sisesta ühe liitri hind"
                type="number"
                onChange={handleTextChange}
                name="price"
                value={dirtyBid.price}
                isInvalid={!!errors?.price}
                step={0.0001}
              />
              <InputGroup.Text style={{ fontSize: '14px' }}>€</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {errors?.price}
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <div className="createOrEditBid__formFields__oilSection__inputContainer">
            <Form.Label className="createOrEditBid__formFields__oilSection__label">
              Pakkumine 22%
            </Form.Label>
            <InputGroup className="createOrEditBid__formFields__oilSection__input">
              <Form.Control
                style={{ fontSize: '14px', background: '#F7F7F7' }}
                type="number"
                onChange={handleTextChange}
                name="priceWithTax"
                value={
                  getNumberDisplayValueVatIncluded(dirtyBid.price) !== "0"
                  ? getNumberDisplayValueVatIncluded(dirtyBid.price, 5)
                    : ""
                }
                step={0.0001}
                disabled
              />
              <InputGroup.Text style={{ fontSize: '14px' }}>€</InputGroup.Text>
            </InputGroup>
          </div>

          {[
            String(OilRequestOilType.DieselSpecial),
            String(OilRequestOilType.Diesel),
          ].includes(oil.oilType) && (
              <div className="createOrEditBid__formFields__oilSection__inputContainer">
                <Form.Label className="createOrEditBid__formFields__oilSection__label">
                  Märgistus
                </Form.Label>
                <Form.Group className="createOrEditBid__formFields__oilSection__input">
                  <Select
                    isClearable
                    onChange={handleSelectChange}
                    name="marking"
                    placeholder=""
                    options={oilMarkingOptions}
                    isInvalid={!!errors?.marking}
                    value={oilMarkingOptions.find(
                      (m) => m.value === dirtyBid.marking,
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.marking}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}

          {isCloudPoint() && (
            <div className="createOrEditBid__formFields__oilSection__inputContainer">
              <Form.Label className="createOrEditBid__formFields__oilSection__label">CP</Form.Label>
              <Form.Group className="createOrEditBid__formFields__oilSection__input">
                <Form.Control
                  style={{ fontSize: '14px', background: '#F7F7F7' }}
                  type="number"
                  onChange={handleTextChange}
                  name="cloudPoint"
                  isInvalid={!!errors?.cloudPoint}
                  value={dirtyBid.cloudPoint}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.cloudPoint}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          )}

          {isColdFilterPlugPoint() && (
            <div className="createOrEditBid__formFields__oilSection__inputContainer">
              <Form.Label className="createOrEditBid__formFields__oilSection__label">CFFP</Form.Label>
              <Form.Group className="createOrEditBid__formFields__oilSection__input">
                <Form.Control
                  style={{ fontSize: '14px', background: '#F7F7F7' }}
                  type="number"
                  onChange={handleTextChange}
                  name="coldFilterPlugPoint"
                  isInvalid={!!errors?.coldFilterPlugPoint}
                  value={dirtyBid.coldFilterPlugPoint}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.coldFilterPlugPoint}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default BidForm;
