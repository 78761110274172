import React, { PureComponent } from "react";
import moment from "moment";
import Request from "./Request";

moment.locale("et");

type Props = {
  oilRequests: any[];
  onReload: (q: any) => void;
};

type State = {
  loading: boolean;
};

class OilRequestList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillUnmount() {
  }

  list = () => {
    const { oilRequests } = this.props;
    return oilRequests.map((request) => {
      return (
        <Request
          key={request.id}
          request={request}
        />
      );
    });
  };

  render() {
    return this.list();
  }
}

export default OilRequestList;
