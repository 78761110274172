import React, { PureComponent } from "react";
import moment from "moment";
import * as date from "../../../utils/date";

moment.locale("et");

type Props = {
  request: any;
  onUpdate: () => void;
};

type State = {
  time: string;
  fetching: boolean;
};

class Clock extends PureComponent<Props, State> {
  private timer: number;

  constructor(props) {
    super(props);

    this.state = {
      time: null,
      fetching: false,
    };
  }

  componentDidMount() {
    this.setState({
      time: moment().toString(),
    });

    this.timer = window.setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  tick = () => {
    this.setState({ time: moment().toString() });
  };

  clearTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  getRequestTimeLeft = () => {
    const { request } = this.props;
    let requestTimeLeft = null;

    if (!this.state.time) {
      return null;
    }

    if (request.status === "Active") {
      const diff = moment(request.endDatetime).diff(moment(this.state.time));
      if (diff < 0) {
        if (!this.state.fetching) {
          this.setState({ fetching: true });
          window.setTimeout(() => {
            this.props.onUpdate();
            this.setState({ fetching: false });
          }, 3000);
        }

        return null;
      }

      requestTimeLeft = date.getDateTimeDifference(
        request.endDatetime,
        this.state.time,
      );
    }

    return requestTimeLeft;
  };

  render() {
    const timeLeft = this.getRequestTimeLeft();
    if (!timeLeft) {
      return null;
    }

    return (
      <>
        {timeLeft}
      </>
    );
  }
}

export default Clock;
