import Big from "big.js";

type OilRequest = {
  oils: {
    markup?: number;
    amount: number;
  }[];
}

export const isMarkup = (oilRequest: OilRequest) => {
  return oilRequest.oils.some((o) => o.markup);
}

export const calculateMarkupTotal = (oilRequest: OilRequest): string => {
  let markup = new Big(0);
  oilRequest.oils.forEach((o) => {
    if (o.markup) {
      markup = markup.plus(new Big(o.markup || 0).mul(o.amount));
    }
  })

  return markup.toFixed();
}
