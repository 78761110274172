import { Helmet } from "react-helmet";
import classNames from "classnames";
import { produce } from "immer";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DOMPurify from "dompurify";

import Message from "./sub/Message";
import { getUsers, getCompanies } from "../../services/turgoil-api";
import { getDateTime } from "../../utils/date";
import Conversation from "./sub/Conversation";
import ConversationMessages from "./sub/ConversationMessages";
import * as userConversationsActions from "../../state/user-conversations";
import * as userNotificationActions from "../../state/user-notifications";
import { RouteComponentProps, withRouter } from "../../common/withRouter";
import PageTitle from "../../common/PageTitle";

type MapStateToProps = {
  user: any;
  userConversations: any;
  userConversationsLoading: any;
  userNotifications: any;
  userNotificationsLoading: any;
  userCompanies: any;
};

type MapDispatchToProps = {
  getUserNotifications: any;
  setUserConversations: any;
};

type Props = MapStateToProps &
  MapDispatchToProps &
  RouteComponentProps<{ id: string }>;

class ConversationPage extends PureComponent<Props, any> {
  private ref: any = React.createRef();
  private resizeTime: any = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      companies: [],
      loading: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    const { userConversations, getUserNotifications, user, params } =
      this.props;

    if (userConversations.length) {
      this.fetchParticipants();
    } else {
      this.setState({ loading: false });
    }
    if (!params.id && !this.isNotificationsRead()) {
      getUserNotifications(user.id);
    }

    this.setState({
      isMobile: window.innerWidth <= 740,
    });

    window.addEventListener("resize", this.resize);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      getUserNotifications,
      params,
      user,
      userConversationsLoading,
      userNotificationsLoading,
    } = this.props;

    if (prevProps.userConversationsLoading && !userConversationsLoading) {
      // Fetch conversation participants
      this.fetchParticipants();
    }

    if (prevProps.userNotificationsLoading && !userNotificationsLoading) {
      // Mark notifications as read
      if (!params.id && !this.isNotificationsRead()) {
        getUserNotifications(user.id);

        if (this.ref.current) {
          this.ref.current.scrollTop = this.ref.current.scrollHeight;
        }
      }
    }

    if (prevProps.params.id !== params.id && !params.id) {
      this.ref.current.scrollTop = this.ref.current.scrollHeight;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  fetchParticipants = async () => {
    const { userConversations } = this.props;
    const { users, companies } = this.state;

    const userIds = new Set();
    const companyIds = new Set();

    userConversations.forEach((userConv: any) => {
      userConv.participants.forEach((participant: any) => {
        if (users.some((user: any) => user.id === participant.userId)) {
          return;
        }
        if (
          companies.some((company: any) => company.id === participant.companyId)
        ) {
          return;
        }
        if (participant.companyId) {
          companyIds.add(participant.companyId);
        }
        if (participant.userId) {
          userIds.add(participant.userId);
        }
      });
    });

    const participantPromises = [];
    if ([...(userIds as any)].length) {
      participantPromises.push(
        getUsers({ userIds: [...(userIds as any)].join(",") }),
      );
    }
    if ([...(companyIds as any)].length) {
      participantPromises.push(
        getCompanies({ companyIds: [...(companyIds as any)].join(",") }),
      );
    }

    if (!participantPromises.length) {
      this.setState({ loading: false });
      return;
    }

    const [nextUsers, nextCompanies] = await Promise.all(participantPromises);

    this.setState(
      (prevState: any) => ({
        users: [...prevState.users, ...nextUsers],
        companies: [...prevState.companies, ...nextCompanies],
        loading: false,
      }),
      () => {
        if (this.ref.current) {
          this.ref.current.scrollTop = this.ref.current.scrollHeight;
        }
      },
    );
  };

  getConversationTitle = (conv: any) => {
    const { companies, users, isMobile } = this.state;
    const { user, userCompanies } = this.props;

    if (isMobile) {
      return `#${conv.oilRequestId}`;
    }

    const title = `#${conv.oilRequestId} – `;

    // eslint-disable-next-line
    for (const participant of conv.participants) {
      if (participant.userId === user.id) {
        // eslint-disable-next-line
        continue;
      }

      if (
        participant.companyId &&
        userCompanies.some(
          (company: any) => company.id === participant.companyId,
        )
      ) {
        // eslint-disable-next-line
        continue;
      }

      if (participant.companyId) {
        const foundCompany = companies.find(
          (company: any) => company.id === participant.companyId,
        );
        if (foundCompany) {
          return `${title} ${foundCompany.companyName}`;
        }
      }

      const foundUser = users.find((u: any) => u.id === participant.userId);
      if (foundUser) {
        return `${title} ${foundUser.name}`;
      }
    }

    return "undefined";
  };

  isNotificationsRead = () => {
    const { userNotifications } = this.props;

    const lastNotification = userNotifications.slice(-1).pop();
    if (!lastNotification) {
      return true;
    }

    return Boolean(lastNotification.readDatetime);
  };

  isConversationRead = (conversation: any) => {
    const { user, userCompanies } = this.props;

    const lastMessage = conversation.messages[0];
    if (!lastMessage) {
      return true;
    }

    if (lastMessage.from.userId === user.id) {
      return true;
    }
    if (
      lastMessage.from.companyId &&
      userCompanies.some(
        (company: any) => company.id === lastMessage.from.companyId,
      )
    ) {
      return true;
    }

    return Boolean(lastMessage.readDatetime);
  };

  handleConversationUpdate = (conversation: any) => {
    const { userConversations, setUserConversations } = this.props;

    const nextConversations = produce(
      userConversations,
      (draftConversations: any) => {
        const foundConversation = draftConversations.find(
          (draftConversation: any) => draftConversation.id === conversation.id,
        );
        if (foundConversation) {
          const message = conversation.messages.slice(-1).pop();
          foundConversation.messages = message ? [message] : [];
        }
      },
    );

    // Conversations are updated in store, to mark last message as read
    setUserConversations(nextConversations);
  };

  displayNotifications = () => {
    const { navigate, getUserNotifications, user } = this.props;

    if (!this.isNotificationsRead()) {
      getUserNotifications(user.id);
    }

    navigate("/conversations", { replace: true });
  };

  displayConversation = (conversationId: any) => {
    const { navigate } = this.props;
    navigate(`/conversations/${conversationId}`, { replace: true });
  };

  resize = () => {
    if (this.resizeTime) {
      clearTimeout(this.resizeTime);
    }
    this.resizeTime = window.setTimeout(() => {
      if (window.innerWidth <= 740 && !this.state.isMobile) {
        this.setState({ isMobile: true });
      } else if (window.innerWidth > 740 && this.state.isMobile) {
        this.setState({ isMobile: false });
      }
    }, 40);
  };

  render() {
    const { loading, isMobile } = this.state;
    const { userNotifications, userConversations, params } = this.props;

    if (loading) {
      return null;
    }

    const lastNotification = userNotifications.slice(-1).pop();
    const foundConversation = userConversations.find(
      (conversation: any) => String(conversation.id) === params.id,
    );

    return (
      <>
        <Helmet>
          <title>Turgoil - Sõnumid</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        </Helmet>
        <div className="conversation-container">
          <PageTitle>Sõnumid</PageTitle>
          <div className="conversation">
            <div
              className={classNames("conversation__left", {
                "conversation__left--mobile": isMobile,
              })}
            >
              <Conversation
                onClick={this.displayNotifications}
                className={classNames({
                  "conversation__conversation--unread":
                    !this.isNotificationsRead(),
                  "conversation__conversation--active": !params.id,
                })}
              >
                {lastNotification && (
                  <>
                    <Conversation.Title>
                      {isMobile && "T"}
                      {!isMobile && (
                        <>
                          <span>Turgoil</span>
                          <small className="text-right">
                            {getDateTime(lastNotification.regDatetime)}
                          </small>
                        </>
                      )}
                    </Conversation.Title>
                    {!isMobile && (
                      <Conversation.Content>
                        <small
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              lastNotification.content,
                              { ALLOWED_TAGS: [] },
                            ),
                          }}
                        />
                        {!this.isNotificationsRead() && (
                          <span className="conversation__conversation-dot" />
                        )}
                      </Conversation.Content>
                    )}
                  </>
                )}
                {!lastNotification && (
                  <>
                    <Conversation.Title>
                      {isMobile && "T"}
                      {!isMobile && <span>Turgoil</span>}
                    </Conversation.Title>
                    {!isMobile && (
                      <Conversation.Content>
                        <small>Teil ei ole ühtegi teavitust.</small>
                      </Conversation.Content>
                    )}
                  </>
                )}
              </Conversation>

              {userConversations.map((conv: any) => (
                <Conversation
                  key={conv.id}
                  onClick={() => this.displayConversation(conv.id)}
                  className={classNames({
                    "conversation__conversation--unread":
                      !this.isConversationRead(conv),
                    "conversation__conversation--active":
                      params.id === String(conv.id),
                  })}
                >
                  {conv.messages.length === 0 && (
                    <>
                      <Conversation.Title>
                        <span>{this.getConversationTitle(conv)}</span>
                        {!isMobile && (
                          <small className="text-right">
                            {getDateTime(conv.regDatetime)}
                          </small>
                        )}
                      </Conversation.Title>
                      <Conversation.Content>
                        <small>–</small>
                      </Conversation.Content>
                    </>
                  )}
                  {conv.messages.length !== 0 && (
                    <>
                      <Conversation.Title>
                        <span>{this.getConversationTitle(conv)}</span>
                        {!isMobile && (
                          <small className="text-right">
                            {getDateTime(conv.messages[0].regDatetime)}
                          </small>
                        )}
                      </Conversation.Title>
                      {!isMobile && (
                        <Conversation.Content>
                          <small
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                conv.messages[0].message,
                                { ALLOWED_TAGS: [] },
                              ),
                            }}
                          />
                          {!this.isConversationRead(conv) && (
                            <span className="conversation__conversation-dot" />
                          )}
                        </Conversation.Content>
                      )}
                    </>
                  )}
                </Conversation>
              ))}
            </div>
            {foundConversation && (
              <div className="conversation__messages-container">
                <ConversationMessages
                  conversationId={foundConversation.id}
                  oilRequestId={foundConversation.oilRequestId}
                  onConversationUpdate={this.handleConversationUpdate}
                />
              </div>
            )}
            {!params.id && (
              <div className="conversation__messages-container">
                <div className="conversation__messages" ref={this.ref}>
                  {!userNotifications.length && "Teil ei ole ühtegi teavitust."}
                  {userNotifications.map((notification: any) => (
                    <Message
                      content={notification.content}
                      datetime={getDateTime(notification.regDatetime)}
                      key={notification.id}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.userReducer.user,
    userConversations: state.userConversationsReducer.conversations,
    userConversationsLoading: state.userConversationsReducer.loading,
    userNotifications: state.userNotificationsReducer.notifications,
    userNotificationsLoading: state.userNotificationsReducer.loading,
    userCompanies: state.userCompaniesReducer.companies,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setUserConversations: (conversations: any) =>
      dispatch(
        userConversationsActions.userConversationsSuccess(conversations),
      ),
    getUserNotifications: (userId: any) =>
      dispatch(userNotificationActions.getUserNotifications(userId, true)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConversationPage));
