import Big from "big.js";

export function currencyFormat(num, precision = 2) {
  return `${num
    .toFixed(precision)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`;
}

export function numberWithSpaces(num: string) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

function countDecimals(number: string): number {
  return number.split(".")[1]?.length || 0;
}


export function getNumberDisplayValue(
  num: string | Big,
  precision?: number,
) {
  const value = new Big(num || 0);
  return numberWithSpaces(countDecimals(value.toFixed()) > precision
    ? value.toFixed(precision)
    : value.toFixed());
}

export function getNumberDisplayValueEUR(
  num: string | Big,
  precision?: number,
) {
  return getNumberDisplayValue(num, precision) + " €";
}

export function getNumberDisplayValueVatIncluded(
  num: string | Big,
  precision?: number,
): string {
  const valueWithTax = new Big(num || 0).times(new Big("1.22"));
  return getNumberDisplayValue(valueWithTax, precision);
}

export function getNumberDisplayValueVatIncludedEUR(
  num: string | Big,
  precision?: number,
): string {
  return getNumberDisplayValueVatIncluded(num, precision) + " €";
}
