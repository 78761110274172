import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "../withRouter";
import ProtectedHeader from "./sub/ProtectedHeader";
import Section from "../Section";
import ProtectedSidebarDesktop from "./sub/ProtectedSidebarDesktop";
import ProtectedSidebarMobile from "./sub/ProtectedSidebarMobile";

function getTitle(path) {
  const reConversations = /\/conversations/g;
  if (reConversations.test(path)) {
    return "Sõnumid";
  }

  switch (path) {
    case "/oil-requests":
      return "Hanked";
    case "/oil-requests/create":
      return "Uus hange";
    case "/settings":
      return "Seaded";
    case "/":
      return "Avaleht";
    default:
      return "";
  }
}

type Props = {
  children: React.ReactNode;
} & RouteComponentProps<unknown>;

type State = {
  showSidebar: boolean;
  isMobile: boolean;
};

class Protected extends Component<Props, State> {
  private resizeTime = 0;

  constructor(props) {
    super(props);

    this.state = {
      showSidebar: false,
      isMobile: window.innerWidth < 768,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    if (this.resizeTime) {
      clearTimeout(this.resizeTime);
    }
    this.resizeTime = window.setTimeout(() => {
      if (window.innerWidth < 768 && !this.state.isMobile) {
        this.setState({ isMobile: true });
      } else if (window.innerWidth >= 768 && this.state.isMobile) {
        this.setState({ isMobile: false });
      }
    }, 40);
  };

  render() {
    const { location } = this.props;
    return (
      <div className="protected-page">
        <div className="protected-page__header-mobile">
          <ProtectedHeader></ProtectedHeader>
          {this.state.isMobile && (
            <div className="protected-page__nav-mobile">
              <ProtectedSidebarMobile pathname={location.pathname}></ProtectedSidebarMobile>
            </div>
          )}
        </div>
        <div className="protected-page__container">
          <Section backgroundColor="#ffffff">
            <div className="protected-page__layout">
              {!this.state.isMobile && (
                <div className="protected-page__nav-desktop">
                  <ProtectedSidebarDesktop pathname={location.pathname}></ProtectedSidebarDesktop>
                </div>
              )}
              <div className="protected-page__content">
                {this.props.children}
              </div>
            </div>
          </Section >
        </div >
      </div >
    )
  }
}

export default withRouter(Protected);
