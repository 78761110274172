import moment from "moment";

moment.locale("et");

/**
 * Gets date according to locale. Example 12. november
 *
 * @param {string} dateTimeString
 * @return {string}
 */
export function getDate(dateTimeString) {
  const now = moment().locale("et");
  const dateTime = moment(dateTimeString);
  if (now.year() !== dateTime.year()) {
    return dateTime.format("DD.MM.YYYY");
  }
  return dateTime.format("D. MMMM");
}

/**
 * Gets date according to format. Example 12.11.2024
 *
 * @param {string} dateTimeString
 * @return {string}
 */
export function get_DD_MM_YYYY(dateTimeString) {
  const now = moment().locale("et");
  const dateTime = moment(dateTimeString);
  return dateTime.format("DD.MM.YYYY");
}


export function get_DD_MM_YYYY_HH_mm(dateTimeString) {
  const now = moment().locale("et");
  const dateTime = moment(dateTimeString);
  return dateTime.format("DD.MM.YYYY HH:mm");
}

/**
 * Gets time according to format. Example 12:00
 *
 * @param {string} dateTimeString
 * @return {string}
 */
export function getTime(dateTimeString) {
  const demandDateTime = moment(dateTimeString);
  return demandDateTime.format("HH:mm");
}

/**
 * Gets time according to format. Example 12:00
 *
 * @param {string} dateTimeString
 * @return {string}
 */
export function getDateTime(dateTimeString) {
  const now = moment();
  const dateTime = moment(dateTimeString);

  if (now.year() !== dateTime.year()) {
    return dateTime.format("DD.MM.YYYY HH:mm");
  }

  return dateTime.format("D. MMMM HH:mm");
}

/**
 * Gets time difference from initial datetime to the target datetime.
 *
 * @param {string} from - ISO 8601
 * @param {string} to - ISO 8601
 */
export function getDateTimeDifference(from, to) {
  const diff = moment(from).diff(moment(to));
  const dur = moment.duration(diff, "milliseconds");
  const hours = Math.floor(dur.asHours());
  let timeDifference = `${hours} h`;
  if (hours < 1) {
    const minutes = Math.floor(dur.asMinutes()) - hours * 60;
    timeDifference = `${minutes} min`;
    if (minutes < 10) {
      const seconds =
        Math.floor(dur.asSeconds()) - Math.floor(dur.asMinutes()) * 60;
      timeDifference = `${minutes} : ${seconds}`;
    }
  }
  return timeDifference;
}

/**
 * Gets time difference from initial datetime to the target datetime.
 *
 * @param {string} from - ISO 8601
 * @param {string} to - ISO 8601
 */
export function getDateDayDifference(from, to) {
  const diff = moment(from).diff(moment(to));
  const dur = moment.duration(diff);
  return dur.asDays();
}
