import { useEffect, useState } from "react";
import OilForm from "./OilForm";
import { OilRequestOilType } from "../../../utils/config";

const getTitleByOilType = (oilCode: string): string => {
    if (OilRequestOilType.Diesel === oilCode) {
        return "Diislikütus";
    }
    if (OilRequestOilType.Petrol95 === oilCode) {
        return "Bensiin 95";
    }
    if (OilRequestOilType.Petrol98 === oilCode) {
        return "Bensiin 98";
    }
    if (OilRequestOilType.DieselSpecial === oilCode) {
        return "Erimärgistusega diislikütus";
    }
    if (OilRequestOilType.HydrotreatedVegetableOil === oilCode) {
        return "HVO-biokütus";
    }
}

const OilFormTable = ({ oils, handleOilChange, oilsErrors }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => { window.removeEventListener('resize', checkIfMobile); };
    }, []);

    return (
        <>
            {isMobile && (
                <div>
                    {oils.map((oil) => (
                        <OilForm
                            key={oil.oilCode}
                            title={getTitleByOilType(oil.oilCode)}
                            oilCode={oil.oilCode}
                            onChange={handleOilChange}
                            error={oilsErrors[oil.oilCode]}
                            isMobile={isMobile}
                        />
                    ))}
                </div>
            )}

            {(!isMobile && oils.length > 0) && (
                <table className="forms-table">
                    <thead>
                        <tr>
                            <th style={{ width: '127px' }}>Kütus</th>
                            <th style={{ width: '234px' }}>Sisesta kogus</th>
                            <th style={{ width: '234px' }}>Märgistus</th>
                            <th style={{ width: '234px' }}>Otstarve</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oils.map((oil) => (
                            <OilForm
                                key={oil.oilCode}
                                title={getTitleByOilType(oil.oilCode)}
                                oilCode={oil.oilCode}
                                onChange={handleOilChange}
                                error={oilsErrors[oil.oilCode]}
                                isMobile={isMobile}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};
export default OilFormTable;