const Icons = {
    User: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#DB9E21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 17V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V17C19 15.3431 17.6569 14 16 14H8C6.34315 14 5 15.3431 5 17Z" stroke="#DB9E21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Email: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 8L10.3359 10.8906C11.3436 11.5624 12.6564 11.5624 13.6641 10.8906L18 8M6 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19Z" stroke="#DB9E21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Call: (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none">
            <path d="M7.11009 16.2279C9.76676 19.3213 13.3044 20.461 15.9107 19.3454C17.0696 18.8494 17.1313 17.1502 16.34 16.0466L15.6788 15.1244C15.1355 14.3667 14.1508 14.3152 13.5514 15.0132C13.1267 15.5077 12.4829 15.6451 11.9528 15.3163C11.2117 14.8566 10.179 14.1444 9.53921 13.3995C8.89943 12.6545 8.28782 11.452 7.89304 10.5891C7.61066 9.97189 7.72863 9.2223 8.15332 8.7278C8.75278 8.0298 8.70856 6.88326 8.05777 6.25063L7.26578 5.48073C6.31799 4.55939 4.85866 4.63128 4.43268 5.98064C3.47464 9.01531 4.45342 13.1345 7.11009 16.2279Z" stroke="#DB9E21" strokeWidth="1.5" />
        </svg>
    ),
    Location: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" stroke="#DB9E21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 3C8.13401 3 5 6.13401 5 10C5 11.7135 5.61568 13.2832 6.63789 14.5L10.373 19.7244C11.1707 20.8401 12.8293 20.8401 13.627 19.7244L17.3621 14.5C18.3843 13.2832 19 11.7135 19 10C19 6.13401 15.866 3 12 3Z" stroke="#DB9E21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Back: (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="9" viewBox="0 0 28 9" fill="none">
            <path d="M28 4.5H1M1 4.5L6 0.5M1 4.5L6 8.5" stroke="#CACACA" />
        </svg>
    ),
    Info: (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M7.00583 9.91667H7M7 4.66667V8.16667M7 12.25C4.1005 12.25 1.75 9.89949 1.75 7C1.75 4.1005 4.1005 1.75 7 1.75C9.89949 1.75 12.25 4.1005 12.25 7C12.25 9.89949 9.89949 12.25 7 12.25Z" stroke="#1A273D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Icons;