import React from "react";

import { OilRequestOilType, OilRequestOilUsageType } from "../../utils/config";
import { getNumberDisplayValue } from "../../utils/format";

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOil(oilType) {
    switch (oilType) {
        case OilRequestOilType.Petrol95:
            return "95";
        case OilRequestOilType.Petrol98:
            return "98";
        case OilRequestOilType.Diesel:
            return "D";
        case OilRequestOilType.DieselSpecial:
            return "EDK";
        case OilRequestOilType.HydrotreatedVegetableOil:
            return "HVO";
        default:
            return "";
    }
}

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOilUsage(oilType) {
    switch (oilType) {
        case OilRequestOilUsageType.Wholesale:
            return "Hulgimüük";
        case OilRequestOilUsageType.Agriculture:
            return "Põllumajandus";
        case OilRequestOilUsageType.Fishing:
            return "Kalapüük";
        default:
            return "";
    }
}

type Props = {
    className?: string;
    oils: any[];
};

class OilsMainPage extends React.PureComponent<Props> {
    render() {
        const { oils } = this.props;

        return (
            <div className="oilList">
                {oils.map((oil) => {
                    const content = (
                        <div className="oilList__oil">
                            <div className="oilList__ring">
                                <span>{getNameForOil(oil.oilType)}</span>
                            </div>
                            <div>
                                <div className="oilList__amount">{getNumberDisplayValue(oil.amount)} L</div>
                                <div className="oilList__description">{getNameForOilUsage(oil.oilUsageType)}</div>
                            </div>
                        </div>
                    );

                    return content;
                })}
            </div>
        );
    }
}

export default OilsMainPage;
