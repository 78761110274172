import React, { PureComponent } from "react";
import classNames from "classnames";
import ReactSelect, { ActionMeta } from "react-select";
import Option from "react-select/dist/declarations/src/components/Option";

function getColor(isSelected, isFocused) {
  if (isSelected) {
    return "#FCA800";
  }
  if (isFocused) {
    return "#ebebeb";
  }
  return "#fff";
}

const customStyles14px = {
  control: styles => ({ ...styles, backgroundColor: 'inherit', fontSize: '14px' }),
  option: (provided, state) => ({
    ...provided,
    color: "#212529",
    fontSize: "14px",
    backgroundColor: getColor(state.isSelected, state.isFocused),
    "&:hover": { backgroundColor: state.isSelected ? "#ffc107" : "#ebebeb" },
  }),
};

const customStyles16px = {
  control: styles => ({ ...styles, backgroundColor: 'inherit', fontSize: '16px' }),
  option: (provided, state) => ({
    ...provided,
    color: "#212529",
    fontSize: "16px",
    backgroundColor: getColor(state.isSelected, state.isFocused),
    "&:hover": { backgroundColor: state.isSelected ? "#ffc107" : "#ebebeb" },
  }),
};


type Props = {
  options: any[];
  value: any;
  name?: string;
  onChange: (
    value: { value: any },
    actionMeta: ActionMeta<{ name?: string }>,
  ) => void;
  isClearable?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  isMulti?: boolean;
  fontSize?: string;
};

class Select extends PureComponent<Props> {
  static defaultProps = {
    isClearable: true,
    placeholder: "",
    isInvalid: false,
    isMulti: false,
    value: undefined,
    fontSize: '16px'
  };

  private input: any = React.createRef();

  handleChange = (option, meta) => {
    if (this.input.current) {
      this.input.current.blur();
    }

    this.props.onChange(option, meta);
  };

  noOptions = () => "Vastet ei leitud";

  render() {
    const { options, isClearable, placeholder, isInvalid, isMulti, value, fontSize } =
      this.props;

    return (
      <ReactSelect
        className={classNames("select", { "is-invalid": isInvalid })}
        classNamePrefix="select"
        ref={this.input}
        name={this.props.name}
        options={options}
        styles={ fontSize === "16px" ? customStyles16px : customStyles14px}
        onChange={this.handleChange}
        noOptionsMessage={this.noOptions}
        isClearable={isClearable}
        placeholder={placeholder}
        isMulti={isMulti}
        value={value}
      />
    );
  }
}

export default Select;
