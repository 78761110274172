const Icons = {
    Plus: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V16M16 12H8M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    PlusYellow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V16M16 12H8M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    PlusMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V16M16 12H8M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    PlusYellowMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V16M16 12H8M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Logout: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 7L8 6C8 4.89543 8.89543 4 10 4L17 4C18.1046 4 19 4.89543 19 6L19 18C19 19.1046 18.1046 20 17 20L10 20C8.89543 20 8 19.1046 8 18L8 17M4 12L15 12M15 12L12 15M15 12L12 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    LogoutMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
            <path d="M5 4L5 3C5 1.89543 5.89543 1 7 1L14 1C15.1046 1 16 1.89543 16 3L16 15C16 16.1046 15.1046 17 14 17L7 17C5.89543 17 5 16.1046 5 15L5 14M1 9L12 9M12 9L9 12M12 9L9 6" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Copy: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 16V4C3 2.89543 3.89543 2 5 2H15M9 22H18C19.1046 22 20 21.1046 20 20V8C20 6.89543 19.1046 6 18 6H9C7.89543 6 7 6.89543 7 8V20C7 21.1046 7.89543 22 9 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    CopyYellow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 16V4C3 2.89543 3.89543 2 5 2H15M9 22H18C19.1046 22 20 21.1046 20 20V8C20 6.89543 19.1046 6 18 6H9C7.89543 6 7 6.89543 7 8V20C7 21.1046 7.89543 22 9 22Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    CopyMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 16V4C3 2.89543 3.89543 2 5 2H15M9 22H18C19.1046 22 20 21.1046 20 20V8C20 6.89543 19.1046 6 18 6H9C7.89543 6 7 6.89543 7 8V20C7 21.1046 7.89543 22 9 22Z" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    CopyYellowMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 16V4C3 2.89543 3.89543 2 5 2H15M9 22H18C19.1046 22 20 21.1046 20 20V8C20 6.89543 19.1046 6 18 6H9C7.89543 6 7 6.89543 7 8V20C7 21.1046 7.89543 22 9 22Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Settings: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.65199 4.56614C9.85534 3.65106 10.667 3 11.6044 3H12.3957C13.3331 3 14.1447 3.65106 14.3481 4.56614L14.551 5.47935C15.2121 5.73819 15.8243 6.09467 16.3697 6.53105L17.2639 6.24961C18.1581 5.96818 19.1277 6.34554 19.5964 7.15735L19.9921 7.84264C20.4608 8.65445 20.3027 9.68287 19.6119 10.3165L18.9217 10.9496C18.9733 11.2922 19 11.643 19 12C19 12.357 18.9733 12.7078 18.9217 13.0504L19.6119 13.6835C20.3028 14.3171 20.4608 15.3455 19.9921 16.1574L19.5964 16.8426C19.1277 17.6545 18.1581 18.0318 17.2639 17.7504L16.3697 17.4689C15.8243 17.9053 15.2121 18.2618 14.551 18.5206L14.3481 19.4339C14.1447 20.3489 13.3331 21 12.3957 21H11.6044C10.667 21 9.85534 20.3489 9.65199 19.4339L9.44906 18.5206C8.78792 18.2618 8.17576 17.9053 7.63031 17.4689L6.73611 17.7504C5.84196 18.0318 4.87231 17.6545 4.40361 16.8426L4.00795 16.1573C3.53925 15.3455 3.69728 14.3171 4.38808 13.6835L5.0783 13.0504C5.02675 12.7077 5.00002 12.357 5.00002 12C5.00002 11.643 5.02675 11.2922 5.0783 10.9496L4.38809 10.3165C3.69729 9.68288 3.53927 8.65446 4.00797 7.84265L4.40362 7.15735C4.87232 6.34554 5.84197 5.96818 6.73613 6.24962L7.63032 6.53106C8.17577 6.09467 8.78793 5.73819 9.44906 5.47935L9.65199 4.56614Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    SettingsYellow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.65202 4.56614C9.85537 3.65106 10.667 3 11.6044 3H12.3957C13.3331 3 14.1447 3.65106 14.3481 4.56614L14.551 5.47935C15.2121 5.73819 15.8243 6.09467 16.3697 6.53105L17.2639 6.24961C18.1581 5.96818 19.1277 6.34554 19.5964 7.15735L19.9921 7.84264C20.4608 8.65445 20.3028 9.68287 19.612 10.3165L18.9218 10.9496C18.9733 11.2922 19.0001 11.643 19.0001 12C19.0001 12.357 18.9733 12.7078 18.9218 13.0504L19.612 13.6835C20.3028 14.3171 20.4608 15.3455 19.9921 16.1574L19.5965 16.8426C19.1278 17.6545 18.1581 18.0318 17.2639 17.7504L16.3698 17.4689C15.8243 17.9053 15.2121 18.2618 14.551 18.5206L14.3481 19.4339C14.1447 20.3489 13.3331 21 12.3957 21H11.6044C10.667 21 9.85537 20.3489 9.65202 19.4339L9.44909 18.5206C8.78796 18.2618 8.17579 17.9053 7.63034 17.4689L6.73614 17.7504C5.84199 18.0318 4.87234 17.6545 4.40364 16.8426L4.00798 16.1573C3.53928 15.3455 3.69731 14.3171 4.38811 13.6835L5.07833 13.0504C5.02678 12.7077 5.00005 12.357 5.00005 12C5.00005 11.643 5.02678 11.2922 5.07833 10.9496L4.38813 10.3165C3.69732 9.68288 3.5393 8.65446 4.008 7.84265L4.40365 7.15735C4.87235 6.34554 5.842 5.96818 6.73616 6.24962L7.63035 6.53106C8.1758 6.09467 8.78796 5.73819 9.44909 5.47935L9.65202 4.56614Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    SettingsMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.65202 4.56614C9.85537 3.65106 10.667 3 11.6044 3H12.3957C13.3331 3 14.1447 3.65106 14.3481 4.56614L14.551 5.47935C15.2121 5.73819 15.8243 6.09467 16.3697 6.53105L17.2639 6.24961C18.1581 5.96818 19.1277 6.34554 19.5964 7.15735L19.9921 7.84264C20.4608 8.65445 20.3028 9.68287 19.612 10.3165L18.9218 10.9496C18.9733 11.2922 19.0001 11.643 19.0001 12C19.0001 12.357 18.9733 12.7078 18.9218 13.0504L19.612 13.6835C20.3028 14.3171 20.4608 15.3455 19.9921 16.1574L19.5965 16.8426C19.1278 17.6545 18.1581 18.0318 17.2639 17.7504L16.3698 17.4689C15.8243 17.9053 15.2121 18.2618 14.551 18.5206L14.3481 19.4339C14.1447 20.3489 13.3331 21 12.3957 21H11.6044C10.667 21 9.85537 20.3489 9.65202 19.4339L9.44909 18.5206C8.78796 18.2618 8.17579 17.9053 7.63034 17.4689L6.73614 17.7504C5.84199 18.0318 4.87234 17.6545 4.40364 16.8426L4.00798 16.1573C3.53928 15.3455 3.69731 14.3171 4.38811 13.6835L5.07833 13.0504C5.02678 12.7077 5.00005 12.357 5.00005 12C5.00005 11.643 5.02678 11.2922 5.07833 10.9496L4.38813 10.3165C3.69732 9.68288 3.5393 8.65446 4.008 7.84265L4.40365 7.15735C4.87235 6.34554 5.842 5.96818 6.73616 6.24962L7.63035 6.53106C8.1758 6.09467 8.78796 5.73819 9.44909 5.47935L9.65202 4.56614Z" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    SettingsYellowMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.65202 4.56614C9.85537 3.65106 10.667 3 11.6044 3H12.3957C13.3331 3 14.1447 3.65106 14.3481 4.56614L14.551 5.47935C15.2121 5.73819 15.8243 6.09467 16.3697 6.53105L17.2639 6.24961C18.1581 5.96818 19.1277 6.34554 19.5964 7.15735L19.9921 7.84264C20.4608 8.65445 20.3028 9.68287 19.612 10.3165L18.9218 10.9496C18.9733 11.2922 19.0001 11.643 19.0001 12C19.0001 12.357 18.9733 12.7078 18.9218 13.0504L19.612 13.6835C20.3028 14.3171 20.4608 15.3455 19.9921 16.1574L19.5965 16.8426C19.1278 17.6545 18.1581 18.0318 17.2639 17.7504L16.3698 17.4689C15.8243 17.9053 15.2121 18.2618 14.551 18.5206L14.3481 19.4339C14.1447 20.3489 13.3331 21 12.3957 21H11.6044C10.667 21 9.85537 20.3489 9.65202 19.4339L9.44909 18.5206C8.78796 18.2618 8.17579 17.9053 7.63034 17.4689L6.73614 17.7504C5.84199 18.0318 4.87234 17.6545 4.40364 16.8426L4.00798 16.1573C3.53928 15.3455 3.69731 14.3171 4.38811 13.6835L5.07833 13.0504C5.02678 12.7077 5.00005 12.357 5.00005 12C5.00005 11.643 5.02678 11.2922 5.07833 10.9496L4.38813 10.3165C3.69732 9.68288 3.5393 8.65446 4.008 7.84265L4.40365 7.15735C4.87235 6.34554 5.842 5.96818 6.73616 6.24962L7.63035 6.53106C8.1758 6.09467 8.78796 5.73819 9.44909 5.47935L9.65202 4.56614Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    Bell: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5.5C14.7614 5.5 17 7.73858 17 10.5V12.7396C17 13.2294 17.1798 13.7022 17.5052 14.0683L18.7808 15.5035C19.6407 16.4708 18.954 18 17.6597 18H6.34025C5.04598 18 4.35927 16.4708 5.21913 15.5035L6.4948 14.0683C6.82022 13.7022 6.99998 13.2294 6.99998 12.7396L7 10.5C7 7.73858 9.23858 5.5 12 5.5ZM12 5.5V3M10.9999 21H12.9999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    BellYellow: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.0001 5.5C14.7615 5.5 17.0001 7.73858 17.0001 10.5V12.7396C17.0001 13.2294 17.1798 13.7022 17.5052 14.0683L18.7809 15.5035C19.6408 16.4708 18.9541 18 17.6598 18H6.34031C5.04604 18 4.35933 16.4708 5.2192 15.5035L6.49486 14.0683C6.82028 13.7022 7.00004 13.2294 7.00004 12.7396L7.00006 10.5C7.00006 7.73858 9.23864 5.5 12.0001 5.5ZM12.0001 5.5V3M11 21H13" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    BellMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.0001 5.5C14.7615 5.5 17.0001 7.73858 17.0001 10.5V12.7396C17.0001 13.2294 17.1798 13.7022 17.5052 14.0683L18.7809 15.5035C19.6408 16.4708 18.9541 18 17.6598 18H6.34031C5.04604 18 4.35933 16.4708 5.2192 15.5035L6.49486 14.0683C6.82028 13.7022 7.00004 13.2294 7.00004 12.7396L7.00006 10.5C7.00006 7.73858 9.23864 5.5 12.0001 5.5ZM12.0001 5.5V3M11 21H13" stroke="#1A273D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    BellYellowMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5.5C14.7614 5.5 17 7.73858 17 10.5V12.7396C17 13.2294 17.1798 13.7022 17.5052 14.0683L18.7808 15.5035C19.6407 16.4708 18.954 18 17.6597 18H6.34025C5.04598 18 4.35927 16.4708 5.21913 15.5035L6.4948 14.0683C6.82022 13.7022 6.99998 13.2294 6.99998 12.7396L7 10.5C7 7.73858 9.23858 5.5 12 5.5ZM12 5.5V3M10.9999 21H12.9999" stroke="#DB9E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    TurgoilLogo: (
        <svg width="111" height="63" viewBox="0 0 111 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_132_571)">
                <path d="M36.6186 49.7343C35.3862 53.5194 32.7454 56.9524 29.2244 59.3291C28.3442 59.8573 27.4639 60.4734 26.4956 60.8255C25.5274 61.1776 24.4711 61.4417 23.5028 61.7058C21.4782 62.1459 19.2776 62.2339 17.253 61.9699C13.1158 61.4417 9.06661 59.5932 6.07375 56.6883C2.99286 53.6955 0.880254 49.8224 0.264076 45.6852C0.0880254 44.6289 0.0880254 43.5726 0 42.5163C0 41.3719 0.176051 40.3156 0.440127 39.3473C0.880254 37.3228 1.49643 35.3862 2.20063 33.4496C3.60904 29.6646 5.28152 25.9675 7.13006 22.4465C10.7391 15.2284 14.6122 8.27439 18.7494 1.40841L19.5416 0L20.3339 1.40841L21.5662 3.43299C22.0063 4.13719 22.3584 4.75337 22.7105 5.45757L24.7351 9.68279L26.7597 13.908C27.4639 15.3164 27.9921 16.7248 28.6963 18.2213C27.0238 15.5805 25.3513 12.9397 23.6788 10.387L21.1261 6.42585C20.686 5.72165 20.2458 5.10547 19.8937 4.40127L19.5416 3.60904C19.5416 3.60904 12.4116 16.1967 8.89056 23.3267C7.13006 26.8477 5.45757 30.4568 4.04917 34.1538C3.34496 36.0024 2.72879 37.8509 2.37669 39.6994C2.20063 40.6677 2.02458 41.548 2.02458 42.4282C2.11261 43.3965 2.11261 44.3648 2.28866 45.245C2.81681 49.0301 4.75337 52.4631 7.48216 55.1039C10.2109 57.7446 13.82 59.4171 17.517 59.8573C19.3656 60.0333 21.3021 60.0333 23.1507 59.5932C24.119 59.4171 24.9992 59.1531 25.8795 58.801L27.1998 58.2728C27.64 58.0967 28.0801 57.9207 28.5202 57.6566C31.8652 56.1602 34.77 53.3434 36.6186 49.7343Z" fill="#DB9E21" />
                <path d="M22.2704 40.4036V29.4005H18.9254V27.816H22.3584L22.5345 22.8866H24.295V27.816H29.2244V29.4005H24.295V40.4917C24.295 43.2205 24.9992 44.1007 27.2878 44.1007H29.4004V45.8612H26.9357C23.5908 45.7732 22.2704 44.1887 22.2704 40.4036ZM33.2735 39.6994V27.728H35.2101V39.4354C35.2101 42.7803 36.5305 44.3648 39.2593 44.3648C41.812 44.3648 43.8366 42.9564 45.333 39.6114V27.728H47.2696V45.7732H45.5971L45.5091 42.2522C43.9246 44.8929 41.812 46.2133 38.9072 46.2133C35.2101 46.2133 33.2735 43.9247 33.2735 39.6994ZM51.9349 27.728H53.6074L53.6954 31.4251C54.7517 28.6082 56.5122 27.3759 59.3291 27.3759H60.4734V29.3124H59.5051C56.4242 29.3124 54.6637 30.9849 53.8715 34.858V45.7732H51.9349V27.728ZM63.4663 47.2696H65.3148C65.4908 49.7343 67.4274 50.8787 70.5963 50.8787C74.4694 50.8787 76.2299 49.2062 76.2299 45.5091V41.724C74.6455 44.1887 72.4448 45.4211 69.628 45.4211C65.4908 45.4211 63.1141 42.7803 63.1141 38.027V34.77C63.1141 30.0167 65.5789 27.3759 69.628 27.3759C72.5329 27.3759 74.7335 28.6963 76.406 31.337L76.494 27.816H78.1665V45.5971C78.1665 50.3505 75.7018 52.6392 70.6843 52.6392C66.195 52.6392 63.7303 50.7906 63.4663 47.2696ZM76.1419 38.9072V33.8898C74.7335 30.8089 72.5329 29.1364 69.8041 29.1364C66.7232 29.1364 65.0507 31.161 65.0507 34.9461V37.8509C65.0507 41.636 66.8112 43.6606 69.8041 43.6606C72.6209 43.6606 74.7335 41.9881 76.1419 38.9072ZM81.7755 38.4671V35.1221C81.7755 30.1047 84.4163 27.3759 89.4337 27.3759C94.3632 27.3759 97.0919 30.1047 97.0919 35.1221V38.4671C97.0919 43.4845 94.3632 46.2133 89.4337 46.2133C84.5043 46.2133 81.7755 43.4845 81.7755 38.4671ZM95.0674 38.291V35.2101C95.0674 31.161 93.1308 29.0484 89.4337 29.0484C85.7367 29.0484 83.8001 31.161 83.8001 35.2101V38.291C83.8001 42.3402 85.7367 44.4528 89.4337 44.4528C93.1308 44.4528 95.0674 42.3402 95.0674 38.291ZM100.613 21.1261H102.814V24.0309H100.613V21.1261ZM100.701 27.728H102.638V45.7732H100.701V27.728ZM108.975 20.0698H110.912V45.7732H108.975V20.0698Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_132_571">
                    <rect width="111" height="62.0579" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Icons;