import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../common/Footer";
import HeaderLight from "../../common/HeaderLight";
import Tutorial from "./sub/Tutorial";
import Section from "../../common/Section";
import LandingSlogan from "./sub/LandingSlogan";
import Benefits from "./sub/Benefits";
import Counters from "./sub/Counters";
import { getStatistics } from "../../state/landing-page";
import { getNumberDisplayValue } from "../../utils/format";

type State = {
  title: string;
  content: string;
  oil_request_count: string;
  liters: string;
  total_sum: string;
};

class LandingPage extends React.PureComponent<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      content: "",
      oil_request_count: "x",
      liters: "x",
      total_sum: "x"
    };
  }

  componentDidMount(): void {
    getStatistics().then((data) => {
      this.setState({
        oil_request_count: data[0].total,
        liters: getNumberDisplayValue(data[1].liters),
        total_sum: getNumberDisplayValue(data[2].totalSum)
      });
    })
      .catch((err) => {
        console.error("Could not fetch counts:")
        console.error(err)
      });
  }

  render() {
    const { title, content, oil_request_count, liters, total_sum } = this.state;

    return (
      <div className="landing-page">
        <Helmet>
          <title>
            Turgoil - vedelkütuse hanked: bensiin 95, bensiin 98, diislikütus,
            erimärgistusega diislikütus
          </title>
          <meta
            name="description"
            content="Soovid osta diislikütust, bensiin 95, bensiin 98, 
						erimärgistusega diislikütust või biokütust? Korralda vedelkütuse hange
						Turgoilis, Eesti vedelkütuse hankeid vahendav veebikeskkond."
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        </Helmet>
        <div className="landing-page">
          <HeaderLight location="landing-page" />
          <Section backgroundColor="#1a273d33">
            <LandingSlogan />
            <Benefits />
          </Section>
          <hr />
          <Section backgroundColor="#fff">
            <Counters oil_request_count={oil_request_count} liters={liters} total_sum={total_sum} />
          </Section>
          <hr />
          <Section backgroundColor="#fff">
            <Tutorial />
          </Section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default LandingPage;


