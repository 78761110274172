import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "../../common/Footer";
import { RootState } from "../../state/store";

type Props = {
  user: any;
};

class HomePage extends PureComponent<Props> {
  render() {
    const { user } = this.props;

    return (
      <>
        <Helmet>
          <title>Turgoil - Avaleht</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        </Helmet>
        <h3 className="page-content__title">Avaleht</h3>
        <div className="homepage">
          <div className="homepage__introduction">
            <img
              className="homepage__introduction-image"
              src="images/svg/analysis.svg"
              alt="introduction"
            />
            <div>
              <h5>
                Tere tulemast, {user.forename} {user.surname}!
              </h5>
              <p>Säästa raha ja aega! Korralda vedelkütuse hange juba täna.</p>
              <p>
                <Link to="/oil-requests/create">
                  <a
                    href="/oil-requests/create"
                    className="btn btn-warning homepage__button rounded"
                  >
                    Uus hange
                  </a>
                </Link>
                <Link to="/oil-requests">
                  <a
                    href="/oil-requests"
                    className="btn btn-warning homepage__button rounded"
                  >
                    Kõik hanked
                  </a>
                </Link>
                <Link to="/settings">
                  <a
                    href="/settings"
                    className="btn btn-warning homepage__button rounded"
                  >
                    Seaded
                  </a>
                </Link>
              </p>
            </div>
          </div>
          <br />
          <hr />
          <h5 className="homepage__question">
            Milline näeb välja hanke korraldamise protsess?
          </h5>
          <div className="homepage__steps">
            <div className="homepage__step">
              <img src="images/svg/create.svg" alt="Loo hange" />
              <p className="homepage__step__title">Samm 1</p>
              <p>
                Menüüst valige "Uus hange", seejärel avaneb Teile vedelkütuse
                hanke korraldamise vaade. Täitke vajalikud väljad ning vajutage
                nuppu "Korralda hange".
              </p>
            </div>
            <div className="homepage__step">
              <img src="images/svg/investigate.svg" alt="Pakkumiste tegemine" />
              <p className="homepage__step__title">Samm 2</p>
              <p>
                Teie hankega tutvuvad Turogil'i poolt heaks kiidetud pakkujad
                ning esitavad Teile pakkumise.
              </p>
            </div>
            <div className="homepage__step">
              <img src="images/svg/winner.svg" alt="save" />
              <p className="homepage__step__title">Samm 3</p>
              <p>
                Kui Teie hange on lõppemas ning Teile on tehtud pakkumisi, siis
                saate valida oma hankele võitja. Seejärel kuvatakse Teile võitja
                kontaktandmed.
              </p>
            </div>
          </div>
          <h5 className="homepage__question">Kuidas saada pakkujaks?</h5>
          <div className="homepage__question-answer">
            Selleks, et osaleda vedelkütuse hangetel, peab Teil olema
            tegevusluba vedelkütuse müügiga tegelemiseks Eestis. Kui Teil on
            tegevusluba, siis kirjutage e-posti aadressile info@turgoil.com, et
            soovite end registreerida pakkujana. Seejärel me kontrollime Teie
            andmete tõesust ning kui kõik on korras, siis teeme Teid pakkujaks.
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.userReducer.user,
  };
}

export default connect(mapStateToProps)(HomePage);
