import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SignInView from "./sub/SignInView";
import { PictureLayoutForm } from "../../common/Layout";

class SignInPage extends PureComponent {
  render() {
    return (
      <PictureLayoutForm>
        <Helmet>
          <title>Turgoil - Logi sisse</title>
          <meta
            name="description"
            content="Logi sisse või loo kasutaja, et korraldada vedelkütuse hange juba täna."
          />
          <script src="https://accounts.google.com/gsi/client?hl=et"></script>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
          {/* <script */}
          {/*   async */}
          {/*   src="https://apis.google.com/js/api:client.js?onload=googleAsyncInit" */}
          {/* /> */}
          {/* <script */}
          {/* 	dangerouslySetInnerHTML={{ */}
          {/* 		__html: ` */}
          {/* 			function googleAsyncInit() { */}
          {/* 				window.gapi.load('auth2', () => { */}
          {/* 					window.auth2 = window.gapi.auth2.init({ */}
          {/* 						client_id: '140069619769-49s5skt5i0s3ft29lsri2qljruvdumo8.apps.googleusercontent.com', */}
          {/* 						cookiepolicy: 'single_host_origin', */}
          {/* 					}); */}
          {/* 				}); */}
          {/* 			} */}
          {/* 	`, */}
          {/* 	}} */}
          {/* /> */}
          {/* <script */}
          {/*   dangerouslySetInnerHTML={{ */}
          {/*     __html: ` */}
          {/* window.fbAsyncInit = function() { */}
          {/* FB.init({ */}
          {/* appId      : '374852050167621', */}
          {/* cookie     : true, */}
          {/* xfbml      : true, */}
          {/* version    : 'v8.0' */}
          {/* }); */}

          {/* FB.AppEvents.logPageView(); */}
          {/* }; */}

          {/* (function(d, s, id){ */}
          {/* var js, fjs = d.getElementsByTagName(s)[0]; */}
          {/* if (d.getElementById(id)) {return;} */}
          {/* js = d.createElement(s); js.id = id; */}
          {/* js.src = "https://connect.facebook.net/en_GB/sdk.js"; */}
          {/* fjs.parentNode.insertBefore(js, fjs); */}
          {/* }(document, 'script', 'facebook-jssdk')); */}
          {/* `, */}
          {/*   }} */}
          {/* /> */}
        </Helmet>
        <div className="login">
          <div className="login__title">Logi sisse</div>
          <div >
            <span className="login__question">Sul ei ole veel kontot? </span>
            <Link to="/signup">
              <a href="/signup" className="login__link">
                Loo konto
              </a>
            </Link>
          </div>
        </div>
        <SignInView />
      </PictureLayoutForm>
    );
  }
}

export default SignInPage;
