import React, { PureComponent } from "react";
import { OilRequestOrder } from "../../../utils/config";

const oilRequestOrderOptions = [
  {
    value: OilRequestOrder.OilRequestEndingAsc,
    label: "Lõppevad enne",
  },
  {
    value: OilRequestOrder.OilRequestRegisteredDesc,
    label: "Viimati lisatud enne",
  },
  {
    value: OilRequestOrder.OilTotalAmountDesc,
    label: "Kogus alates suuremast",
  },
  {
    value: OilRequestOrder.OilTotalAmountAsc,
    label: "Kogus alates väiksemast",
  },
];

/**
 * @param {number} totalOilRequests
 * @param {number} limit
 * @return {number}
 */
function getMaximumPage(totalOilRequests, limit) {
  return Math.ceil(totalOilRequests / limit) || 1;
}

type Props = {
  onPageChange: (pg: number) => void;
  totalOilRequests: number;
  limit: number;
  page: number;
};

type State = {
  page: number;
  inputFocus: boolean;
};

class OilRequestPagination extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      inputFocus: false,
    };
  }

  handlePageInputChange = (e) => {
    this.setState({ page: e.target.value });
  };

  handlePageInputFocus = () => {
    const { page } = this.props;

    this.setState({
      page,
      inputFocus: true,
    });
  };

  handlePageInputBlur = () => {
    const { page } = this.state;
    const { onPageChange } = this.props;
    this.setState({ inputFocus: false });
    onPageChange(page);
  };

  handleNextPage = () => {
    const { limit, onPageChange, page, totalOilRequests } = this.props;
    const maximumPage = getMaximumPage(totalOilRequests, limit);

    if (page < maximumPage) {
      onPageChange(page + 1);
    }
  };

  handlePreviousPage = () => {
    const { page, onPageChange } = this.props;

    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  handleMaximumPageClick = () => {
    const { onPageChange, limit, totalOilRequests } = this.props;
    const maximumPage = getMaximumPage(totalOilRequests, limit);
    onPageChange(maximumPage);
  };

  getOptionValue = (currentValue) =>
    oilRequestOrderOptions.find((option) => option.value === currentValue);

  render() {
    const { limit, page, totalOilRequests } = this.props;
    const { inputFocus } = this.state;

    const maximumPage = getMaximumPage(totalOilRequests, limit);

    return (
      <div className="pagination">
        <div className="pagination__container">
          <button
            type="button"
            id="pagination__button--left"
            className="pagination__button"
            onClick={this.handlePreviousPage}
          >
            <img src="images/svg/left-caret.svg" alt="left" />
          </button>
          <input
            className="form-control"
            name="page"
            onBlur={this.handlePageInputBlur}
            onChange={this.handlePageInputChange}
            onFocus={this.handlePageInputFocus}
            type="text"
            value={inputFocus ? this.state.page : page}
          />
          <button
            className="pagination__button"
            type="button"
            onClick={this.handleMaximumPageClick}
          >
            {maximumPage}
          </button>
          <button
            id="pagination__button--right"
            className="pagination__button"
            type="button"
            onClick={this.handleNextPage}
          >
            <img src="images/svg/right-caret.svg" alt="right" />
          </button>
        </div>
      </div>
    );
  }
}

export default OilRequestPagination;
