import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipWrapper = ({ children, id, text }) => {
    return (
        <OverlayTrigger
            key={id}
            placement={'top'}
            overlay={
                <Tooltip id={`tooltip-top` + id} style={{ position: "fixed" }}>
                    {text}
                </Tooltip>
            }
        >
            <div style={{ cursor: 'pointer', width: 'fit-content' }}>
                {children}
            </div>

        </OverlayTrigger>
    );
};

export default TooltipWrapper;
