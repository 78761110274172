import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "../../../common/Select";
import { OilRequestOilType } from "../../../utils/config";
import {
  oilAdditiveOptions,
  oilMarkingOptions,
  oilPurposeOptions,
} from "../helpers";

export type Oil = {
  oilCode: string;
  amount: string;
  // hasBioAdditive?: boolean;
  // hvo?: string;
  marking?: string;
  purpose?: string;
};

type Props = {
  title: string;
  oilCode: string;
  isMobile: boolean;
  onChange: (oil: Oil) => void;
  error?: Partial<Record<keyof Oil, string>>;
};

const OilForm: React.FC<Props> = ({
  oilCode,
  title,
  error,
  isMobile,
  onChange,
}): React.ReactElement => {
  const [oil, setOil] = useState<Oil>({ oilCode, amount: "" });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOil({
      ...oil,
      [name]: value,
    });
  };

  const handleSelectChange = (
    o: { value: any } | null,
    m: { name?: string },
  ) => {
    const { value } = o || {};
    const { name } = m;
    setOil({
      ...oil,
      [name]: value,
    });
  };

  useEffect(() => {
    onChange(oil);
  }, [oil, onChange]);

  return (
    <>
      {!isMobile ?
        <tr>
          <td>{title}</td>
          <td>
            <InputGroup>
              <Form.Control
                style={{ fontSize: '14px' }}
                type="number"
                onChange={handleTextChange}
                name="amount"
                isInvalid={!!error?.amount}
              />
              <InputGroup.Text>Liitrit</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {error?.amount}
              </Form.Control.Feedback>
            </InputGroup>
          </td>
          <td>
            {[
              String(OilRequestOilType.DieselSpecial),
              String(OilRequestOilType.Diesel),
            ].includes(oilCode) && (
                <Form.Group>
                  <Select
                    isClearable
                    onChange={handleSelectChange}
                    name="marking"
                    placeholder=""
                    options={oilMarkingOptions}
                    isInvalid={!!error?.marking}
                    fontSize="14px"
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.marking}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
          </td>
          <td>
            {[String(OilRequestOilType.DieselSpecial)].includes(oilCode) && (
              <Form.Group>
                <Select
                  isClearable
                  onChange={handleSelectChange}
                  name="purpose"
                  placeholder=""
                  options={oilPurposeOptions}
                  isInvalid={!!error?.purpose}
                  fontSize="14px"
                />
                <Form.Control.Feedback type="invalid">
                  {error?.purpose}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </td>
        </tr>
        :
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="is-mobile__title">{title}</div>
          <div>
            <div className="is-mobile__input_label">Sisesta kogus</div>
            <InputGroup>
              <Form.Control
                style={{ fontSize: '14px' }}
                type="number"
                onChange={handleTextChange}
                name="amount"
                isInvalid={!!error?.amount}
              />
              <InputGroup.Text>Liitrit</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {error?.amount}
              </Form.Control.Feedback>
            </InputGroup>
          </div>

          {[
            String(OilRequestOilType.DieselSpecial),
            String(OilRequestOilType.Diesel),
          ].includes(oilCode) && (
              <div style={{ marginTop: '17px' }}>
                <div className="is-mobile__input_label">Märgistus</div>
                <Form.Group>
                  <Select
                    isClearable
                    onChange={handleSelectChange}
                    name="marking"
                    placeholder=""
                    options={oilMarkingOptions}
                    isInvalid={!!error?.marking}
                    fontSize="14px"
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.marking}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}
          {[String(OilRequestOilType.DieselSpecial)].includes(oilCode) && (
            <div style={{ marginTop: '17px' }}>
              <div className="is-mobile__input_label">Otstarve</div>
              <Form.Group>
                <Select
                  isClearable
                  onChange={handleSelectChange}
                  name="purpose"
                  placeholder=""
                  options={oilPurposeOptions}
                  isInvalid={!!error?.purpose}
                  fontSize="14px"
                />
                <Form.Control.Feedback type="invalid">
                  {error?.purpose}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default OilForm;
