import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { isMarkup } from "../../../utils/oilRequest";
import Bid from "./Bid";

type OilRequestBid = {
  markup: string;
  id: string;
} & Record<string, any>;

type OilRequest = {
  markup?: string;
  status: string;
  user: {
    id: number;
  };
  oils: any[];
  bidId?: number;
};

type Props = {
  oilRequestBids: OilRequestBid[];
  oilRequest: OilRequest;
  onWinner: (bidId: string, oils?: {
    id: number;
    markup: number;
  }[]) => void;
};

const OilRequestBidTable: React.FC<Props> = ({
  oilRequestBids,
  oilRequest,
  onWinner,
}): React.ReactElement => {
  const user = useSelector((state: RootState) => state.userReducer.user);

  if (!oilRequestBids || oilRequestBids.length === 0) {
    return <div className="bids-section__no-bids">Pole ühtegi pakkumist.</div>;
  }

  return (
    <>
      <div className="bids-section__desktop">
        <Table className="bids-section__table">
          <thead>
            <tr style={{ backgroundColor: 'white' }}>
              <th className="bids-section__header">Pakkuja</th>
              <th className="bids-section__header">Pakkumine</th>
              {!isMarkup(oilRequest) && <th className="bids-section__header">Pakkumine 22%</th>}
              <th className="bids-section__header">Kommentaar</th>
              {isMarkup(oilRequest) && <th className="bids-section__header">Juurdehindlus</th>}
              {isMarkup(oilRequest) && <th className="bids-section__header">Summa</th>}
              {isMarkup(oilRequest) && <th className="bids-section__header">Summa 22%</th>}
              <th className="bids-section__header">Lisatud</th>
              <th className="bids-section__header" style={{ width: 0 }} />
            </tr>
          </thead>
          <tbody>
            {oilRequestBids.map((b) => (
              <Bid
                key={b.id}
                requestStateCode={oilRequest.status}
                user={user}
                bid={b}
                oilRequestOils={oilRequest.oils}
                onOilRequestWinner={onWinner}
                requesterId={oilRequest.user.id}
                winnerBidId={oilRequest.bidId}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <div className="bids-section__mobile">
        {oilRequestBids.map((b) => (
          <Bid
            key={b.id}
            requestStateCode={oilRequest.status}
            user={user}
            bid={b}
            oilRequestOils={oilRequest.oils}
            onOilRequestWinner={onWinner}
            requesterId={oilRequest.user.id}
            winnerBidId={oilRequest.bidId}
          />
        ))}
      </div>
    </>
  );
};

export default OilRequestBidTable;
