import { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Button,
  useAccordionButton,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";

import { validateEmail } from "../../../utils/regex";
import * as userCompaniesActions from "../../../state/user-companies";
import * as toastActions from "../../../state/toasts";
import * as date from "../../../utils/date";
import Loader from "../../../common/Loader";
import { ToastType } from "../../../common/Toasts";
import {
  getRegistryCompanies,
  createUserCompany,
  editUserCompany,
} from "../../../services/turgoil-api";
import TooltipWrapper from "../../CreateOilRequestPage/sub/TooltipWrapper";

function CustomAccordionToggle({ children }) {
  const decoratedOnClick = useAccordionButton("");

  return (
    <Button type="button" variant="light" onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}

type Props = {
  getUserCompanies: typeof userCompaniesActions.getUserCompanies;
  showToast: typeof toastActions.showToast;
  companies: any[];
  user: any;
};

type C = {
  registryCode: string;
  contactPhone: string;
  contactEmail: string;
};

type State = {
  openCreateModal: boolean;
  openEditModal: boolean;
  fetchingCompany: boolean;
  fetchedCompanyInfo: any;
  loading: boolean;
  errors: Record<string, string>;
  company: C;
  successMessage?: string;
};

function getCompanyStatusDisplayName(companyStatus: string): string {
  switch (companyStatus) {
    case "R":
      return "Registrisse kantud";
    case "L":
      return "Likvideerimisel";
    case "N":
      return "Pankrotis";
    case "K":
      return "Kustutatud";
    default:
      return "";
  }
}

class Company extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      openCreateModal: false,
      fetchingCompany: false,
      openEditModal: false,
      fetchedCompanyInfo: undefined,
      loading: false,
      errors: {},
      company: {
        registryCode: "",
        contactPhone: "",
        contactEmail: "",
      },
    };
  }

  componentDidMount() {
    const { user, getUserCompanies } = this.props;
    getUserCompanies(user.id);
  }

  openCreateModal = () => {
    this.setState({
      fetchedCompanyInfo: undefined,
      openCreateModal: true,
      company: {
        registryCode: "",
        contactPhone: "",
        contactEmail: "",
      },
    });
  };

  validate = () => {
    const errors: Record<string, string> = {};
    const { company } = this.state;

    if (company.registryCode && company.registryCode.length !== 8) {
      errors.registryCodeError = "Registrikoodi pikkus peab olema 8 tähemärki.";
    }
    if (company.contactEmail && !validateEmail(company.contactEmail)) {
      errors.contactEmailError =
        "See ei ole korrektne e-posti aadress. Korrektne on näiteks info@turgoil.com";
    }
    if (company.contactPhone && company.contactPhone.length > 20) {
      errors.contactPhoneError =
        "Telefoni numbri maksimaalne pikkus on 20 tähemärki.";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleCreate = async (e) => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const { company } = this.state;
    const { user, getUserCompanies, showToast } = this.props;

    this.setState({ loading: true, successMessage: undefined });
    try {
      const res = await createUserCompany(user.id, company);
      const emailMessage = res.email ? `Teie taotlus juriidilise isiku lisamise kohta platvormile on edastatud äriregistris olevale e-posti aadressile ${res.email} ja ootab kinnitamist. ` : "Teie taotlus juriidilise isiku lisamise kohta platvormile on edastatud platvormi haldajale. ";
      this.setState({
        successMessage: `Ettevõte edukalt lisatud. ${emailMessage}Peale kinnitamist on Teil võimalik korraldada vedelkütuste hankeid lisatud ettevõtte nimel. Vajadusel pöörduge platvormi haldaja poole info@turgoil.com.`,
      })
    } catch (err) {
      this.setState({ loading: false });
      showToast({
        title: "Päring ebaõnnestus.",
        text: "Selline ettevõtte on juba registreeritud. Kui tegemist on Teie ettevõttega, siis võtke ühendust klienditoega info@turgoil.com.",
        type: ToastType.Error,
      });

      return;
    }

    try {
      // Silent success
      await getUserCompanies(user.id);
    } catch (err) {
      showToast({
        title: "Päring ebaõnnestus.",
        text: err.message,
        type: ToastType.Error,
      });
    }

    this.setState({
      openCreateModal: false,
      loading: false,
      company: {} as C,
    });
  };

  handleEdit = async (e) => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const { company } = this.state;
    const { companies, getUserCompanies, showToast, user } = this.props;

    const foundCompany = companies.find(
      (c) => c.registryCode === company.registryCode,
    );
    if (!foundCompany) {
      return;
    }

    this.setState({ loading: true });

    try {
      await editUserCompany(user.id, foundCompany.id, {
        contactPhone: company.contactPhone,
        contactEmail: company.contactEmail,
      });
      showToast({
        title: "Päring õnnestus.",
        text: "Ettevõte edukalt muudetud.",
        type: ToastType.Success,
      });
    } catch (err) {
      this.setState({ loading: false });
      showToast({
        title: "Päring ebaõnnestus.",
        text: err.message,
        type: ToastType.Error,
      });

      return;
    }

    try {
      // Silent success
      await getUserCompanies(user.id);
    } catch (err) {
      showToast({
        title: "Päring ebaõnnestus.",
        text: err.message,
        type: ToastType.Error,
      });
    }

    this.setState({
      openEditModal: false,
      company: {
        registryCode: "",
        contactPhone: "",
        contactEmail: "",
      },
      loading: false,
    });
  };

  handleCompanyChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [`${name}Error`]: "",
      },
    }));
  };

  handleRegistryCodeChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [`${name}Error`]: "",
      },
    }));

    if (e.target.value.length === 8) {
      this.fetchCompanyInformation(e.target.value);
    }
  };

  fetchCompanyInformation = (registryCode) => {
    const { showToast } = this.props;

    this.setState({
      fetchingCompany: true,
      fetchedCompanyInfo: undefined,
    });

    getRegistryCompanies({ registryCode })
      .then((companiesRes) => {
        if (!companiesRes.length) {
          this.setState({ fetchingCompany: false });
          showToast({
            title: "Päring ebaõnnestus.",
            text: "Kahjuks sellist ettevõtet ei leitud. Kui olete veendunud, et sellise registrikoodiga ettevõtte on olemas, siis võtke klienditoega ühendust.",
            type: ToastType.Error,
          });

          return;
        }

        this.setState({
          fetchedCompanyInfo: companiesRes[0],
          fetchingCompany: false,
        });
      })
      .catch(() => {
        this.setState({
          fetchingCompany: false,
        });
      });
  };

  closeModal = () => {
    this.setState({
      openCreateModal: false,
      openEditModal: false,
      errors: {},
      company: {} as C,
    });
  };

  openEditModal = (companyId) => {
    const { companies } = this.props;
    const company = companies.find((c) => c.id === companyId);

    this.setState({
      openEditModal: true,
      errors: {},
      company: {
        registryCode: company.registryCode,
        contactPhone: company.contactPhone,
        contactEmail: company.contactEmail,
      },
    });
  };

  renderCompanyRow = () => {
    const { user } = this.props;
    return this.props.companies.map((c) => (
      <tr key={c.regDatetime ? c.registryCode : c.key}>
        <td>{c.companyName || "—"}</td>
        <td>{c.registryCode}</td>
        <td>{c.contactPhone || "—"}</td>
        <td>{c.contactEmail || "—"}</td>
        <td>{c.regDatetime ? date.getDateTime(c.regDatetime) : "—"}</td>
        <td>{getCompanyStatusDisplayName(c.companyStatus)}</td>
        <td>{c.confirmed ? "Jah" : "Ei"}</td>
        <td>
          {c.userId === user.id && (
            <TooltipWrapper id="1" text="Muuda">
              <i
                role="button"
                onClick={() => this.openEditModal(c.id)}
                className="bi bi-pencil-square"
              />
            </TooltipWrapper>
          )}
        </td>
      </tr>
    ))
  };

  render() {
    const { companies } = this.props;
    const {
      openCreateModal,
      openEditModal,
      loading,
      company,
      errors,
      fetchedCompanyInfo,
      fetchingCompany,
      successMessage,
    } = this.state;

    return (
      <div className="settings__content">
        {successMessage && <Alert variant="warning">{successMessage}</Alert>}
        {!loading && (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="fw-normal">Ettevõtte nimi</th>
                    <th className="fw-normal">Registrikood</th>
                    <th className="fw-normal">Telefon</th>
                    <th className="fw-normal">E-posti aadress</th>
                    <th className="fw-normal">Lisatud</th>
                    <th className="fw-normal">Staatus</th>
                    <th className="fw-normal">Kinnitatud</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.renderCompanyRow()}
                  {!companies.length && (
                    <tr>
                      <td colSpan={9}>Te ei ole lisanud ühtegi ettevõtet.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="settings__footer">
              <CustomAccordionToggle>Loobu</CustomAccordionToggle>
              <Button
                className="float-end"
                variant="warning"
                type="button"
                onClick={this.openCreateModal}
              >
                Lisa ettevõte
              </Button>
            </div>

            <Modal
              show={openCreateModal || openEditModal}
              animation={false}
              onHide={this.closeModal}
              size="sm"
            >
              <Modal.Header closeButton>
                {openEditModal ? "Ettevõtte muutmine" : "Ettevõtte lisamine"}
              </Modal.Header>
              <Modal.Body>
                {fetchingCompany && <Loader />}
                <Form
                  onSubmit={
                    openCreateModal ? this.handleCreate : this.handleEdit
                  }
                >
                  {!fetchingCompany && fetchedCompanyInfo && (
                    <Form.Group className="mb-2">
                      <Form.Label>Ettevõtte nimi</Form.Label>
                      <Form.Control
                        readOnly
                        plaintext
                        value={fetchedCompanyInfo.companyName}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-2">
                    <Form.Label htmlFor="registryCode">Registrikood</Form.Label>
                    <Form.Control
                      id="registryCode"
                      name="registryCode"
                      value={company.registryCode}
                      readOnly={openEditModal}
                      plaintext={openEditModal}
                      onChange={this.handleRegistryCodeChange}
                      maxLength={8}
                      isInvalid={Boolean(errors.registryCodeError)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.registryCodeError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label htmlFor="contactEmail">
                      Kontakt e-posti aadress
                    </Form.Label>
                    <Form.Control
                      id="contactEmail"
                      name="contactEmail"
                      value={company.contactEmail}
                      onChange={this.handleCompanyChange}
                      isInvalid={Boolean(errors.contactEmailError)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactEmailError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label htmlFor="contactPhone">
                      Kontakt telefon
                    </Form.Label>
                    <Form.Control
                      id="contactPhone"
                      name="contactPhone"
                      value={company.contactPhone}
                      onChange={this.handleCompanyChange}
                      isInvalid={Boolean(errors.contactPhoneError)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactPhoneError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button type="button" variant="light" onClick={this.closeModal}>
                  Loobu
                </Button>
                <Button
                  variant="warning"
                  style={{ marginLeft: "auto" }}
                  type="button"
                  onClick={
                    openCreateModal ? this.handleCreate : this.handleEdit
                  }
                >
                  Salvesta
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {loading && <Loader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: state.userCompaniesReducer.companies,
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserCompanies: (userId) =>
      dispatch(userCompaniesActions.getUserCompanies(userId)),
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
